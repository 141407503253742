@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  color: #4b5563;
}

input,
textarea {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 0.375em !important;
  border-width: 0 !important;
  @apply ring-1 ring-inset ring-gray-300;
}

input:focus,
textarea:focus {
  border-color: #50c063 !important;
  --tw-ring-color: #50c063 !important;
}

#modal-background {
  background-color: rgba(0 0 0 / 40%) !important;
}

#tooltip {
  background: #444;
  color: white;
  font-weight: bold;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  display: none;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  left: -4px;
}

#tooltip[data-show] {
  display: block;
}

#div_id_remember {
  @apply flex items-center;
}

#div_id_remember label {
  -webkit-box-ordinal-group: 3;
  order: 2;
  margin-bottom: 0;
  margin-left: 0.5rem;
  font-weight: 400;
}

input[type="checkbox"] {
  border: 1px solid #d1d5db;
  color: #50c063;
  border-radius: 0.25rem;
}

input[type="checkbox"]:focus {
  --tw-ring-color: #50c063;
}

.btn-90 {
  min-width: 90px;
}

#place-form #div_id_description {
  flex-grow: 1;
  margin-right: 8px;
  margin-bottom: 0;
}

#place-form input {
  width: 240px;
}

.btn-long {
  min-width: 140px;
}

#place-edit-form > div {
  @apply col-span-1;
}

.btn-base {
  line-height: 34px;
  @apply flex items-center justify-center rounded-md px-3 py-[0] border ring-1 ring-inset ring-gray-300;
}

.btn-primary {
  line-height: 34px;
  @apply flex items-center justify-center rounded-md px-3 py-[0] border ring-1 ring-inset ring-primary;
  @apply bg-primary text-white hover:bg-primary-light;
}
